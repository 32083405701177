import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import PageWhyNowHeroSection from "../components/sections/page-why-now-hero-section"
import PageWhyNowIntroSection from "../components/sections/page-why-now-intro-section"
import PageWhyNowReasonsSection from "../components/sections/page-why-now-reasons-section"
import BenefitsSection from "../components/sections/benefits-section"
import CapabilitiesSection from "../components/sections/capabilities-section"
import QuickQuoteSection from "../components/sections/quick-quote-section"
import CtaSection from "../components/sections/cta-section"
import SimpleProcessSection from "../components/sections/simple-process-section"

const WhyNowPage = () => (
  <Layout>
    <Seo
      title="Why Now"
      description="The cost of higher security and reliability is minimal when you are proactive."
      noIndex
    />

    <PageWhyNowHeroSection />
    <PageWhyNowIntroSection />
    <SimpleProcessSection />
    <PageWhyNowReasonsSection />
    <BenefitsSection />
    <CapabilitiesSection />
    <QuickQuoteSection />
    <CtaSection />
  </Layout>
)

export default WhyNowPage
