import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import styled from "styled-components";

const StyledDiv = styled.div`
  .accordion__button {
    color: #1f2937;
    font-weight: 700;
    font-size: 28px;
    background-color: #ffffff;
  }
  .accordion__button:before {
    color: #4183c4;
    height: 18px;
    width: 18px;
    margin-right: 14px;
  }
  .accordion__item {
    font-size: 18px;
    background-color: #ffffff;
    border: 1px solid #4183c4;
  }
`

const PageWhyNowReasonsSection = () => {
  return (
    <div className="bg-gray-100 w-full py-16">
      <div className="w-11/12 max-w-screen-lg mx-auto">
        <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-8'>Managing the Change</h2>

        <p className='text-lg text-gray-800 text-center mb-8'>Even though the urgency may be high, new clients sometimes tell us they are hesitant to make a change. Here are some of the most common reasons:</p>
      </div>

      <StyledDiv className="w-11/12 max-w-screen-xl mx-auto">
        <Accordion preExpanded={['a']}>
          <AccordionItem uuid="a">
            <AccordionItemHeading>
              <AccordionItemButton>
                We’re cleaning up troubles with several IT projects right now. Once we get that completed, we should talk.
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>If you have already started the project we’re happy to provide advice if things don’t progress as you would like.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                We are leasing our equipment directly from our IT service provider today.
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>We see this more than we would like. IT providers try to keep themselves more sticky to you by locking you into a lease and making money from the hardware. IT Proactive will only connect you with finance companies if purchasing outright isn’t an option.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                We want to make a change, but we’re concerned that doing so will require time and energy from our staff.
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>The cost to change now for higher security and reliability is minimal when compared with the potential cost of an incident that leaves your business at risk.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                There’s a key employee who helps us in this area now. Whichever way we go forward, we don’t want to lose them
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>We regularly work with onsite IT staff. The days are long gone when one person could effectively manage an entire business network. One person is unable to single-handedly manage help-desk, proactive measures, project management, and security. IT Proactive will provide you with a team of skilled professionals who each have expertise in their respective areas.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                We really need a more inclusive and proactive service, but we’re concerned that we can’t afford the cost.
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>Proactive Managed IT Services are an investment that will prove to be not only cost-effective but will save money with pre-planning. Let us show you how IT Proactive’s roadmap will produce quantifiable, and measurable results for your bottom line.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>
                If we swap providers, you will have to work closely with our current provider to transition.
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>We will create a transition plan so that you, your current provider, and our staff are all on the same page. It’s also important to point out that as part of working with IT Proactive, we develop and completely document your IT infrastructure and keep that documentation up to date. In this way, you will be prepared for any staffing or provider changes in the future.</p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </StyledDiv>
    </div>
  );
};

export default PageWhyNowReasonsSection;