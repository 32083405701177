import React from 'react'

const PageWhyNowIntroSection = () => (
  <div className="bg-white w-full border-b py-16">
    <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Consider the Cost of Waiting</h2>

    <div className="w-11/12 max-w-screen-xl flex flex-col xl:flex-row items-center justify-center mx-auto">
      <div className='w-full lg:w-2/3 py-2 px-6'>
        <p className='text-2xl md:text-3xl text-gray-800 font-bold mb-6'>The cost of higher security and reliability is minimal when you are proactive</p>

        <ul className='list-disc mb-6 ml-8'>
          <li className='text-lg text-gray-800 mb-4'>How many IT support problems come up in a month? What would it save your employees in time and frustration if that went down by half?</li>

          <li className='text-lg text-gray-800 mb-4'>Have you experienced a successful cyber intrusion? Consider the lost productivity of that event, any service provider costs, equipment, or software changes.</li>

          <li className='text-lg text-gray-800 mb-4'>How much time does your staff spend dealing with hardware, software, cloud, and network support staff? What is that cost?</li>

          <li className='text-lg text-gray-800 mb-4'>Many small and mid-size businesses have senior leadership staff overseeing IT strategy and resolving issues. Wouldn’t their time be better spent on driving the business?</li>

          <li className='text-lg text-gray-800 mb-4'>When was the last time that you or your staff experienced delays in project work because of unexpected IT issues? Who was affected? What did the lost productivity cost?</li>
        </ul>
      </div>

      <div className='w-full lg:w-1/3 border-4 border-site-lightblue p-6'>
        <h2 className='text-lg uppercase font-medium mb-8'>Fact:</h2>

        <p className='text-xl md:text-2xl text-gray-800 text-center font-semibold mx-4'>
          A managed services partner’s expertise and their ability to efficiently solve IT challenges, organizations should help you see significant cost saving by filling the skills gap and avoiding extended downtime, lag time and recurring IT issues.
        </p>

        <p className='text-site-lightblue text-right mt-8'>Source: Upwork & Gartner 2022</p>
      </div>
    </div>
  </div>
)

export default PageWhyNowIntroSection