import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

const SimpleProcessSection = () => (
  <div className="bg-white w-full py-16 border-t border-b">
    <div className="w-11/12 max-w-screen-xl mx-auto">
      <h2 className='text-4xl md:text-5xl text-site-lightblue text-center font-bold mb-16'>Our Simple Process</h2>

      <div className='grid grid-cols-1 xl:grid-cols-3 gap-8'>
        <div>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>Stabilize</h2>
          <div className='w-48 w- h-48 mx-auto'>
            <StaticImage
              src='../../images/stabilize-icon.png'
              width={800}
              layout="constrained"
              alt="Stabilize"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>First 30 Days</h3>
          <p className='text-lg text-gray-800 text-center'>In the first 30 days following our onsite discovery, we focus on closing any critical holes we find the network. These are items that could cause downtime or lead to a security incident.</p>
        </div>

        <div>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>Standardize</h2>
          <div className='w-48 w- h-48 mx-auto'>
            <StaticImage
              src='../../images/standardize-icon-1.png'
              width={800}
              layout="constrained"
              alt="Standardize"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>30-90 Days</h3>
          <p className='text-lg text-gray-800 text-center'>The following 30-90 days our proactive team is focused on identifying the items that are out of align with industry best practices. Your Technology Advisor will present these findings and our recommendations.</p>
        </div>

        <div>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold text-center uppercase mb-6'>Strategize</h2>
          <div className='w-48 w- h-48 mx-auto'>
            <StaticImage
              src='../../images/strategize-icon.png'
              width={800}
              layout="constrained"
              alt="Strategize"
              formats={["auto", "webp", "avif"]}
            />
          </div>
          <h3 className='text-3xl text-site-lightblue font-bold text-center mt-6 mb-6 border-b pb-3'>90 Day Cycle</h3>
          <p className='text-lg text-gray-800 text-center'>Once your network is standardized, your Technology Advisor will collaborate with you to point your growth objectives and workflow challenges. Our expert team will then craft an IT roadmap, laying out the essential solutions to achieve your goals.</p>
        </div>
      </div>
    </div>
  </div>
)

export default SimpleProcessSection